import React from "react";
import "../Assets/Styles/Testimonials.css";
import { Carousel } from "react-bootstrap";
import { IMAGES } from "../utils";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Testimonials = () => {
  return (
    <>
      <div className="testimonials-section">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="test-main-wrapper">
              <div className="test-wrapper">
                <div className="test-header">
                  <h5>Testimonials</h5>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <h3>We help to achieve mutual goals.</h3>
                  </AnimationOnScroll>
                </div>
                <div className="test-reviews">
                  <Carousel indicators={false} variant="dark">
                    <Carousel.Item>
                      <img
                        className="test-profile"
                        src={IMAGES.Profile}
                        alt="First slide"
                      />
                      <div className="test-review-wrap">
                        <h5>Leading IT Service Provider, Dubai</h5>
                        <p className="designation">
                          Program Management Director
                        </p>
                        <p className="review-para">
                          Aceis is our trusted partner and an extended team
                          executing our Center of Excellence in India. They have
                          been instrumental in fulfilling our strategic staffing
                          goals. The Aceis/COE consultants are part of every
                          success story we have delivered to our end customers.
                          The Aceis team is a pleasure to work with and we are
                          confident it will be a long collaboration together.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="test-profile"
                        src={IMAGES.Profile}
                        alt="First slide"
                      />
                      <div className="test-review-wrap">
                        <h5>Global IT Consultancy, US</h5>
                        <p className="designation">Delivery Head </p>
                        <p className="review-para">
                          It has been a great experience working with Aceis.
                          They understand the customer requirements very well
                          and respond with a quick turn-around. They have an
                          excellent team of consultants.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="test-profile"
                        src={IMAGES.Profile}
                        alt="First slide"
                      />
                      <div className="test-review-wrap">
                        <h5>Leading IT Company, Canada</h5>
                        <p className="designation">General Director</p>
                        <p className="review-para">
                          Aceis has got the best and out of the box resourcing
                          models and a very competitive rate card. It definitely
                          helped us to provide the services to our end customers
                          with a competitive edge and to win the deals. We thank
                          Aceis team for their contributions in our success and
                          look forward to the same in future.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="test-profile"
                        src={IMAGES.Profile}
                        alt="First slide"
                      />
                      <div className="test-review-wrap">
                        <h5>Leading Manufacturing Company, India</h5>
                        <p className="designation">Group CIO</p>
                        <p className="review-para">
                          We have been associated with Aceis for a very long
                          time and we continue to do so… for the reason that
                          they never compromise on the quality of the services.
                          They also are very flexible and accommodative towards
                          the customer requirements and situations. We have seen
                          their growth trajectory and wish them all the best for
                          future expansions. And at the same time look forward
                          to successful collaboration with them.
                        </p>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="test-right-section">
              <img src={IMAGES.TestimonialRight} alt="" />  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
