import React, { useState, useEffect } from "react";
import Companies from "../sections/Companies";
import Testimonials from "../sections/Testimonials";
import Banner from "../sections/Banner";
import ServicesOfAcies from "../sections/ServicesOfAcies";
import Opportunities from "../sections/Opportunities";
import Industries from "../sections/IndustriesSupport";
import HowWeWorks from "../sections/HowWeWorks";
import ItConsultancyServices from "../sections/ItConsultancyservices";
import AboutUs from "./AboutUs";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  return (
    <>
      {/* <TrainingModule /> */}
      <Banner />
      <ServicesOfAcies />
      <ItConsultancyServices />
      <Industries />
      <Companies />
      <Testimonials />
      <HowWeWorks />
      <Opportunities />
     
    </>
  );
};

export default Home;
