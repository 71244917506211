const IMAGES = {
  RoundAbstract: require("../Assets/Images/abstract-4.png"),

  Profile: require("../Assets/Images/Profile.png"),
  Logo: require("../Assets/Images/AceisWeblogo.png"),
  bgRequestcallBack: require("../Assets/Images/bgRequestcallBack.jpg"),
  BG1: require("../Assets/Images/bg-1.png"),
  BG2: require("../Assets/Images/bg-2.png"),
  FAQs: require("../Assets/Images/faqhome.jpeg"),
  Img1: require("../Assets/Images/img-1.jpg"),
  Img2: require("../Assets/Images/img-2.jpg"),
  Shape12: require("../Assets/Images/shape-12.png"),
  ServicesSideImg: require("../Assets/Images/Aceis/ai.jpg"),
  staffingai: require("../Assets/Images/Aceis/staffing-ai.jpg"),

  groupdiscussion: require("../Assets/Images/Aceis/groupdiscussion.png"),
  Code: require("../Assets/Images/Aceis/code.png"),
  launch: require("../Assets/Images/Aceis/launch.png"),
  planning: require("../Assets/Images/Aceis/planning.png"),

  //testimonials
  TestimonialRight: require("../Assets/Images/TestimonialRight.jpg"),

  Shape20: require("../Assets/Images/shape-20.png"),
  ceo: require("../Assets/Images/Aceis/ceo.jpg"),
  ItConsultancy: require("../Assets/Images/Aceis/ItConsultancyService.jpg"),
  cybersecurity: require("../Assets/Images/Aceis/cybersecurity.jpg"),
  staffing: require("../Assets/Images/Aceis/staffing.jpg"),
  stratehicstaffing: require("../Assets/Images/Aceis/stratehicstaffing.jpg"),
  // Home Page Banner
  AceisSlider: require("../Assets/Images/Aceis/slider-1.jpg"),
  HomeGraphicsPage: require("../Assets/Images/HomeBanner/GraphicsDesign.png"),
  HomeGraphicsBGPage: require("../Assets/Images/HomeBanner/GraphicsDesignBg.png"),
  HomeDigitalPage: require("../Assets/Images/HomeBanner/DigitalMarketing.png"),

  Servicebg: require("../Assets/Images/service-img.jpg"),
  aboutUsSection2: require("../Assets/Images/aboutUsSection2.jpg"),
  aboutbg: require("../Assets/Images/aboutbg.jpg"),
  aboutUsSection1: require("../Assets/Images/aboutUsSection1.jpg"),
  DMLandingImage: require("../Assets/Images/Digitalmarketing/landingImage.avif"),
  DM7: require("../Assets/Images/Digitalmarketing/DM7.avif"),
  DM8: require("../Assets/Images/Digitalmarketing/DM8.png"),
  whatIsDm: require("../Assets/Images/Digitalmarketing/whatIsDm.jpg"),
  aboutUsSlider: require("../Assets/Images/Digitalmarketing/aboutSlide.jpg"),
  missionVission: require("../Assets/Images/mission.png"),

  // module

  module_1: require("../Assets/Images/Digitalmarketing/module_1.jpg"),
  syllabus_bg: require("../Assets/Images/Digitalmarketing/syllabus/syllabus_bg.jpg"),
  a1: require("../Assets/Images/Digitalmarketing/a1.jpg"),
  a2: require("../Assets/Images/Digitalmarketing/a2.jpg"),
  a3: require("../Assets/Images/Digitalmarketing/a3.jpg"),

  // Graphics

  bestGd1: require("../Assets/Images/GraphicsDesign/bestgd_1.webp"),
  bestGd2: require("../Assets/Images/GraphicsDesign/bestgd_2.webp"),
  whatIsGd: require("../Assets/Images/GraphicsDesign/whatIsGd.jpg"),
  whyGd: require("../Assets/Images/GraphicsDesign/whyGd.jpg"),
  faqGd: require("../Assets/Images/GraphicsDesign/FAQ.png"),

  //service
  ServicesWebDesign: require("../Assets/Images/Services/website-design.avif"),
  ServicesDM: require("../Assets/Images/Services/DigitalMarketing.jpg"),
  ServicesGD: require("../Assets/Images/Services/graphics-Design.avif"),
  ServicesITTraining: require("../Assets/Images/Services/it-traing.avif"),
  WebsiteDev: require("../Assets/Images/Services/WebsiteDevelopment.jpg"),

  WebDesignIcon1: require("../Assets/Images/Services/icon1.png"),
  WebDesignIcon2: require("../Assets/Images/Services/icon2.png"),
  Marquee1: require("../Assets/Images/Services/marquee-website.jpg"),
  WebDesignSolution: require("../Assets/Images/Services/WebDesignSolutions.jpg"),

  DThrillWebDesign: require("../Assets/Images/Services/DThrillWebDesign.jpg"),
  ResponsiveWebDesign: require("../Assets/Images/Services/ResponsiveWebDesign.jpg"),
  DesignExperience: require("../Assets/Images/Services/DesignExperience.jpg"),
  Brand: require("../Assets/Images/Services/Brand.jpg"),

  UserExp: require("../Assets/Images/Services/UserExperience.jpg"),
  TechExpertise: require("../Assets/Images/Services/TechnicalExpertise.jpg"),
  MobileFriendly: require("../Assets/Images/Services/MobileFriendly.jpg"),

  DMAudit: require("../Assets/Images/Services/Audit.jpg"),
  DMSEOStrategy: require("../Assets/Images/Services/SEOStrategy.jpg"),
  DMOnSiteSEO: require("../Assets/Images/Services/On-siteSEO.jpg"),
  DMImgWorking: require("../Assets/Images/Services/working-progress.png"),

  DMHappyCustomer: require("../Assets/Images/Services/HappyCustomer.png"),
  DMExpertAdvisor: require("../Assets/Images/Services/ExpertAdvisor.png"),
  DMProjectCompleted: require("../Assets/Images/Services/ProjectCompleted.png"),

  DMPartnership: require("../Assets/Images/Services/partnership.png"),
  DMInnovation: require("../Assets/Images/Services/innovation.png"),
  DMStratergy: require("../Assets/Images/Services/Market-Strategy.png"),
  DMFunction: require("../Assets/Images/Services/support-function.png"),
  DMResearch: require("../Assets/Images/Services/Marketing-research.png"),
  DMLead: require("../Assets/Images/Services/lead.png"),
  DMImgAbout: require("../Assets/Images/Services/dm-about.png"),

  GDBanner: require("../Assets/Images/Services/GraphicDesign/GraphicsDesignBanner.jpg"),
  GDPrintDesignGreen: require("../Assets/Images/Services/GraphicDesign/print-design-green.jpg"),
  GDPrintDesignPurple: require("../Assets/Images/Services/GraphicDesign/print-design-purple.jpg"),
  GDBrand1: require("../Assets/Images/Services/GraphicDesign/Mockup1.jpg"),
  GDBrand2: require("../Assets/Images/Services/GraphicDesign/Mockup2.jpg"),
  GDBrand3: require("../Assets/Images/Services/GraphicDesign/Mockup3.jpg"),
  GDBrand4: require("../Assets/Images/Services/GraphicDesign/Mockup4.jpg"),
  GDMan: require("../Assets/Images/Services/GraphicDesign/home-cybersecurity-contact-bg-image.png"),
  GDVisitingCard: require("../Assets/Images/Services/GraphicDesign/visiting-card.png"),
  GDBrochure: require("../Assets/Images/Services/GraphicDesign/brochure.png"),
  GDCatalogue: require("../Assets/Images/Services/GraphicDesign/catalogue.png"),
  GDCorporateIdentity: require("../Assets/Images/Services/GraphicDesign/brand-identity.png"),
  GDInvititionCard: require("../Assets/Images/Services/GraphicDesign/invitation.png"),
  GDAdvertisement: require("../Assets/Images/Services/GraphicDesign/advertising.png"),
  GDNewsletter: require("../Assets/Images/Services/GraphicDesign/information.png"),
  GDGreetings: require("../Assets/Images/Services/GraphicDesign/greeting-card.png"),
  GDBook: require("../Assets/Images/Services/GraphicDesign/book.png"),
  Abstract1: require("../Assets/Images/Services/abstract-2.png"),
  Abstract2: require("../Assets/Images/Services/abstract-3.png"),

  WebDesign2: require("../Assets/Images/web-design2.png"),
  WebDev2: require("../Assets/Images/web-development2.png"),
  Graphic2: require("../Assets/Images/graphic-designing2.png"),
  Digital2: require("../Assets/Images/digital-marketing2.png"),
  ContactLady: require("../Assets/Images/contact-lady.png"),

  // Our Courses Section
  CSWebDesign: require("../Assets/Images/OurCourses(Home)/Web Design.jpg"),
  CSWebDev: require("../Assets/Images/OurCourses(Home)/WebDevelopment.jpg"),
  CSFullStack: require("../Assets/Images/OurCourses(Home)/FullStack.jpg"),
  CSDigital: require("../Assets/Images/OurCourses(Home)/DigitalMarketing.jpg"),
  CSGraphics: require("../Assets/Images/OurCourses(Home)/GraphicDesigner.jpg"),
  CSPython: require("../Assets/Images/OurCourses(Home)/Python.jpg"),
  CSPowerBi: require("../Assets/Images/OurCourses(Home)/PowerBI.jpg"),
  CSBA: require("../Assets/Images/OurCourses(Home)/BusinessAnalyst.jpg"),
  CSSoftTesting: require("../Assets/Images/OurCourses(Home)/SoftwareTesting.jpg"),
  CSAptitute: require("../Assets/Images/OurCourses(Home)/AptitudeCourses.jpg"),

  // Logos
  DreamEstate: require("../Assets/Images/OurPartners/DreamEstateLogo.png"),
  AbhitaLand: require("../Assets/Images/OurPartners/abhitaland.png"),
  AbhitaFinance: require("../Assets/Images/OurPartners/AbhitaFinance.png"),
  Legavac: require("../Assets/Images/OurPartners/logolegavac.png"),
  Cowork: require("../Assets/Images/OurPartners/cowork.png"),
  Year99: require("../Assets/Images/OurPartners/99YearsLogo.png"),
  Bsideu: require("../Assets/Images/OurPartners/Bsideu.png"),
  CityBank: require("../Assets/Images/OurPartners/CityBankLogo.png"),
  OBC: require("../Assets/Images/OurPartners/OBC.png"),
  SahuBankGramin: require("../Assets/Images/OurPartners/SahuBankGramin.png"),
  SahuBankMulti: require("../Assets/Images/OurPartners/SahuBankMulti.png"),

  //Mobile Development

  MobileAppBanner: require("../Assets/Images/MobileDev/MobileAppMainBanner.jpg"),
  MobileAppSingle: require("../Assets/Images/MobileDev/single-mb-1.png"),
  MobileInnoCard: require("../Assets/Images/MobileDev/icon.png"),
  MobileParallax: require("../Assets/Images/MobileDev/mb-parallax.jpg"),
  MobileIOS: require("../Assets/Images/MobileDev/apple-mb.png"),
  MobileAndroid: require("../Assets/Images/MobileDev/mobiles2.png"),
  MobileIdea: require("../Assets/Images/MobileDev/IdeaAndPlanning.jpg"),
  MobileWireframing: require("../Assets/Images/MobileDev/WireframingAndDesign.jpg"),
  MobileDevelopment: require("../Assets/Images/MobileDev/Development.jpg"),
  MobileTesting: require("../Assets/Images/MobileDev/Testing.jpg"),
  MobileDeployment: require("../Assets/Images/MobileDev/Deployment.jpg"),
  MobileMaintenance: require("../Assets/Images/MobileDev/MaintenanceAndUpdates.jpg"),
  MobileAppCardimg: require("../Assets/Images/MobileDev/MobileAppCardimg.jpg"),

  //DM Modules Images
  DigitalMarketingOverview: require("../Assets/Images/ModulesInDigitalMarketing/DigitalMarketingOverview.jpg"),
  WebsiteCreation: require("../Assets/Images/ModulesInDigitalMarketing/WebsiteCreation.jpg"),
  SocialMediaAutomation: require("../Assets/Images/ModulesInDigitalMarketing/SocialMediaAutomation.jpg"),
  GraphicsDesigningUsingCanva: require("../Assets/Images/ModulesInDigitalMarketing/GraphicsDesigningUsingCanva.jpg"),
  InstagramMarketing: require("../Assets/Images/ModulesInDigitalMarketing/InstagramMarketing.jpg"),
  FaceBookMarketing: require("../Assets/Images/ModulesInDigitalMarketing/FaceBookMarketing.jpg"),
  TwitterMarketing: require("../Assets/Images/ModulesInDigitalMarketing/TwitterMarketing.jpg"),
  SocialMediaOptimization: require("../Assets/Images/ModulesInDigitalMarketing/SocialMediaOptimization.jpg"),
  LinkedinMarketing: require("../Assets/Images/ModulesInDigitalMarketing/LinkedinMarketing.jpg"),
  SMSMarketing: require("../Assets/Images/ModulesInDigitalMarketing/SMSMarketing.jpg"),
  LeadGenerationForBusiness: require("../Assets/Images/ModulesInDigitalMarketing/LeadGenerationForBusiness.jpg"),
  WhatsAppMarketing: require("../Assets/Images/ModulesInDigitalMarketing/WhatsAppMarketing.jpg"),
  FreelancingProjects: require("../Assets/Images/ModulesInDigitalMarketing/FreelancingProjects.jpg"),
  YouTubeSEOAdsenseAnalytics: require("../Assets/Images/ModulesInDigitalMarketing/YouTubeSEOAdsense&Analytics.jpg"),
  GoogleAnalytics: require("../Assets/Images/ModulesInDigitalMarketing/GoogleAnalytics.jpg"),
  OnlineReputationManagement: require("../Assets/Images/ModulesInDigitalMarketing/OnlineReputationManagement.jpg"),
  AffiliateMarketing: require("../Assets/Images/ModulesInDigitalMarketing/AffiliateMarketing.jpg"),
  MakingMoneyViaGoogleAdsense: require("../Assets/Images/ModulesInDigitalMarketing/MakingMoneyViaGoogleAdsense.jpg"),
  GoogleSearchConsole: require("../Assets/Images/ModulesInDigitalMarketing/GoogleSearchConsole.jpg"),
  LocalSEO: require("../Assets/Images/ModulesInDigitalMarketing/LocalSEO.jpg"),
  QuoraMarketing: require("../Assets/Images/ModulesInDigitalMarketing/QuoraMarketing.jpg"),
  OffpageStategyPlanningExecution: require("../Assets/Images/ModulesInDigitalMarketing/OffpageStategyPlanning&Execution.jpg"),
  SearchEngineOptimization: require("../Assets/Images/ModulesInDigitalMarketing/SearchEngineOptimization.jpg"),
  ContentMarketing: require("../Assets/Images/ModulesInDigitalMarketing/ContentMarketing.jpg"),
  EnrollMarketing: require("../Assets/Images/ModulesInDigitalMarketing/EnrollMarketing.jpg"),
  RemarketingConversion: require("../Assets/Images/ModulesInDigitalMarketing/Remarketing&Conversion.jpg"),
  VideoYoutubeMarketing: require("../Assets/Images/ModulesInDigitalMarketing/Video-YoutubeMarketing.jpg"),
  MobileMarketing: require("../Assets/Images/ModulesInDigitalMarketing/MobileMarketing.jpg"),
  OnlineDisplayAdvertising: require("../Assets/Images/ModulesInDigitalMarketing/OnlineDisplayAdvertising.jpg"),
  AppStoreOptimization: require("../Assets/Images/ModulesInDigitalMarketing/AppStoreOptimization.jpg"),
  MobileAppCreation: require("../Assets/Images/ModulesInDigitalMarketing/MobileAppCreation.jpg"),
  VideoEditingUsingOnlineTool: require("../Assets/Images/ModulesInDigitalMarketing/VideoEditingUsingOnlineTool.jpg"),
  BusinessGrowthStrategy: require("../Assets/Images/ModulesInDigitalMarketing/BusinessGrowthStrategy.jpg"),
  DropShipping: require("../Assets/Images/ModulesInDigitalMarketing/DropShipping.jpg"),
  PersonalBrandingUsingDigitalMarketing: require("../Assets/Images/ModulesInDigitalMarketing/PersonalBrandingUsingDigitalMarketing.jpg"),
  InfluencerMarketing: require("../Assets/Images/ModulesInDigitalMarketing/InfluencerMarketing.jpg"),
  ECommerceDevelopmentMarketing: require("../Assets/Images/ModulesInDigitalMarketing/E-CommerceDevelopment&Marketing.jpg"),
  MediaPlanning: require("../Assets/Images/ModulesInDigitalMarketing/MediaPlanning.jpg"),
  PublicReaction: require("../Assets/Images/ModulesInDigitalMarketing/PublicReaction.jpg"),
  MasterPlanning: require("../Assets/Images/ModulesInDigitalMarketing/MasterPlanning.jpg"),
  SalesTraining: require("../Assets/Images/ModulesInDigitalMarketing/SalesTraining.jpg"),
  MarketingHacks: require("../Assets/Images/ModulesInDigitalMarketing/MarketingHacks.jpg"),
  ViralMarketing: require("../Assets/Images/ModulesInDigitalMarketing/ViralMarketing.jpg"),
  GrowthHacking: require("../Assets/Images/ModulesInDigitalMarketing/GrowthHacking.jpg"),
  EntrepreneurshipForBusiness: require("../Assets/Images/ModulesInDigitalMarketing/EntrepreneurshipForBusiness.jpg"),
  StartupPlanningStrategy: require("../Assets/Images/ModulesInDigitalMarketing/StartupPlanning&Strategy.jpg"),
  LeadershipFundamentals: require("../Assets/Images/ModulesInDigitalMarketing/LeadershipFundamentals.jpg"),
  SocialMediaMarketing: require("../Assets/Images/ModulesInDigitalMarketing/SocialMediaMarketing.jpg"),
  Advertising: require("../Assets/Images/ModulesInDigitalMarketing/Advertising.jpg"),
  DigitalDisplayAdvertising: require("../Assets/Images/ModulesInDigitalMarketing/DigitalDisplayAdvertising.jpg"),
  Business: require("../Assets/Images/ModulesInDigitalMarketing/Business.jpg"),
  WebAnalytics: require("../Assets/Images/ModulesInDigitalMarketing/WebAnalytics.jpg"),
  Marketingcommunications: require("../Assets/Images/ModulesInDigitalMarketing/Marketingcommunications.jpg"),
  SearchEnginemarketing: require("../Assets/Images/ModulesInDigitalMarketing/SearchEnginemarketing.jpg"),
  WebDesign: require("../Assets/Images/ModulesInDigitalMarketing/WebDesign.jpg"),
  MarketingAutomation: require("../Assets/Images/ModulesInDigitalMarketing/MarketingAutomation.jpg"),
  SearchAdvertising: require("../Assets/Images/ModulesInDigitalMarketing/SearchAdvertising.jpg"),
  Media: require("../Assets/Images/ModulesInDigitalMarketing/Media.jpg"),
  DigitalMedia: require("../Assets/Images/ModulesInDigitalMarketing/DigitalMedia.jpg"),
  GoogleAds: require("../Assets/Images/ModulesInDigitalMarketing/GoogleAds.jpg"),
  Writing: require("../Assets/Images/ModulesInDigitalMarketing/Writing.jpg"),

  // Why DM
  IncreasedReach: require("../Assets/Images/WhyDM/IncreasedReach.jpg"),
  GreaterTargeting: require("../Assets/Images/WhyDM/GreaterTargeting.jpg"),
  CostEffective: require("../Assets/Images/WhyDM/Cost-Effective.jpg"),
  MeasurableResults: require("../Assets/Images/WhyDM/MeasurableResults.jpg"),
  FlexibilityAndAgility: require("../Assets/Images/WhyDM/FlexibilityAndAgility.jpg"),

  // Web Design
  LearnWebDesignCourseInPune: require("../Assets/Images/WebDesign/LearnWebDesignCourseInPune.jpg"),
  WhatIsUserInterfaceUI: require("../Assets/Images/WebDesign/WhatIsUserInterfaceUI.jpg"),
  WhatIsUserExperience: require("../Assets/Images/WebDesign/WhatIsUserExperience.jpg"),
  HTML: require("../Assets/Images/WebDesign/HTML.jpg"),
  CSS: require("../Assets/Images/WebDesign/CSS.jpg"),
  Bootstrap: require("../Assets/Images/WebDesign/Bootstrap.jpg"),
  JavaScript: require("../Assets/Images/WebDesign/JavaScript.jpg"),
  Wordpress: require("../Assets/Images/WebDesign/Wordpress.jpg"),
  CourseEligibility: require("../Assets/Images/WebDesign/CourseEligibility.jpg"),

  //Why Graphics Design?
  VisualCommunication: require("../Assets/Images/Aceis/AI-Workflows.jpg"),
  BrandingAndMarketing: require("../Assets/Images/Aceis/Cyber-Securities.jpg"),
  AestheticAppeal: require("../Assets/Images/Aceis/IT-Cunsulting-Services.jpg"),
  Professionalism: require("../Assets/Images/Aceis/Offshore-COE-Execution.jpg"),
  Creativity: require("../Assets/Images/Aceis/Strategic-Staffing.jpg"),

  //Software Used In Graphics Design
  Ps: require("../Assets/Images/GraphicsDesign/PS.png"),
  Ai: require("../Assets/Images/GraphicsDesign/AI.png"),
  Pr: require("../Assets/Images/GraphicsDesign/PR.png"),
  Ae: require("../Assets/Images/GraphicsDesign/AE.png"),
  Coral: require("../Assets/Images/GraphicsDesign/Corel.png"),

  //blogs
  Compition: require("../Assets/Images/Blog/compition.jpg"),
  BlogInnerDM: require("../Assets/Images/Blog/DigitalMarketing.jpg"),

  // webdev
  watWebDevDo: require("../Assets/Images/web-dev-do.png"),

  //HowWeWorks
  HowWorkDiscuss: require("../Assets/Images/HowWeWorks/groupdiscussion.png"),
  HowWorkArrow: require("../Assets/Images/HowWeWorks/arrow.png"),
  HowWorkIdea: require("../Assets/Images/HowWeWorks/planning-svgrepo-com (2).png"),
  HowWorkCode: require("../Assets/Images/HowWeWorks/code.png"),
  HowWorkLaunch: require("../Assets/Images/HowWeWorks/launch.png"),
  //footer brand logo
  Sutrahr: require("../Assets/Images/Brand/Bigthings.png"),
  timeandwatch: require("../Assets/Images/Brand/dozeworks.png"),
  staffcare: require("../Assets/Images/Brand/ilinkdigital.jpg"),
  umbrella: require("../Assets/Images/Brand/neomedic.png"),
  hopin: require("../Assets/Images/Brand/oppchain.png"),
  Nextwave: require("../Assets/Images/Brand/servicely.png"),

  //IndustriesSupport
  group: require("../Assets/Images/group.png"),
  //it
  SalesforceConsulting: require("../Assets/Images/IT/1.jpg"),
  SharePointConsulting: require("../Assets/Images/IT/2.jpg"),
  SplunkConsulting: require("../Assets/Images/IT/3.jpg"),
};
export { IMAGES };
