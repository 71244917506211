import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./layouts/Header";
import FooterComponent from "./layouts/Footer";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import { useEffect, useState } from "react";
import { IMAGES } from "./utils";
import { MdClose } from "react-icons/md";
import { iucontact } from "./config/api";
import AboutUs from "./Pages/AboutUs";
import ServicesOfAceis from "./Pages/Services";
import StaffingStrategy from "./Pages/StaffingStrategy";
import AIWorkflow from "./Pages/AIWorkflow";
function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [state, setState] = useState({
    ContactID: 0,
    FirstName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    Message: "",
    CreatedBy: 1,
    errors: [],
  });
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
  };
  const handleOffCanvasClose = () => {
    clearState();
  };
  const clearState = () => {
    setState({
      ContactID: 0,
      FirstName: "",
      LastName: "",
      EmailID: "",
      Mobile: "",
      Message: "",
      CreatedBy: 1,
      errors: "",
    });
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.FirstName) {
      IsValid = false;
      errors["FirstName"] = "Full Name Is Required";
    } else if (state.FirstName.length < 2) {
      IsValid = false;
      errors["FirstName"] = "Must Be 15 Characters ";
    } else if (!state.FirstName.match(regex_name)) {
      IsValid = false;
      errors["FirstName"] = "Please Enter Valid Name";
    } else {
      errors["FirstName"] = "";
    }

    if (!state.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (state.Mobile.length < 10 || state.Mobile.length > 10) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!state.EmailID) {
      IsValid = false;
      errors["EmailID"] = "Email ID Is Required";
    } else if (!state.EmailID.match(regex_email)) {
      IsValid = false;
      errors["EmailID"] = "Please Enter valid Email id";
    } else {
      errors["EmailID"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = async () => {
    console.log("submit-->", state);
    if (validate()) {
      await iucontact(state).then((res) => {
        console.log("contact--->", res[0]);
        if (res.length == 1) {
          alert("You are Successfully Registed with us...!!");
          clearState();
        }
      });
    } else {
      alert("Please Enter Proper Details..!");
    }
  };

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/ServicesOfAceis" element={<ServicesOfAceis />} />
        <Route path="/StaffingStrategy" element={<StaffingStrategy />} />
        <Route path="/AIWorkflow" element={<AIWorkflow />} />
      </Routes>
      <FooterComponent />
    </>
  );
}

export default App;
