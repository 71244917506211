import React, { useState, useEffect } from "react";
import "../Assets/Styles/AboutUs.css";
import { IMAGES } from "../utils";

const AboutUs = () => {
  return (
    <>
      <section className="AboutSection-2">
        <div className="container">
          <div className=" AboutSection-2_bg">
            <div className="AboutSection-2Wrapper">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="AboutSection-2_imageWrapper">
                    <div className="AboutSection-2_Img">
                      <img src={IMAGES.aboutUsSection2} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="aboutsectionTextWrapper">
                    <div className="aboutsectionText">
                      <h3>We are Aceis </h3>
                      <p>
                        <strong className="fs-5">Welcome !</strong>
                      </p>
                      <p>
                        Aceis is a sincere effort to bring an extra edge to your
                        digital transformation, automation initiative and
                        strategic staffing with an objective of providing great
                        customer experience.
                      </p>
                      <p>
                        <strong className="fs-5">
                          {" "}
                          <a href="https://.com/" target="_blank">
                            Aceis
                          </a>
                        </strong>{" "}
                        was founded by seasoned consultants from global IT and
                        product organizations with varied experience in Service
                        and Program Management, Automation, Managed Services
                        using leading products and industry best practices.
                      </p>
                      <p>
                        We help businesses in their IT priorities and goals with
                        our array of services – AI Workflows, Digital Marketing
                        Managed Services, Strategic Staffing, Execution of
                        Offshore COE and IT Advisory and Consulting in –
                        Cybersecurity, Salesforce, Splunk and SharePoint.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutusSection-2_cardsBody">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="cardsWrapper">
                    <div className="training">
                      <h3>Mission</h3>
                      <p>
                        Our mission is to empower organizations with the
                        technology they need to succeed. We strive to understand
                        our clients’ unique needs and goals and provide them
                        with customized IT solutions that drive their growth and
                        success. We are committed to delivering exceptional
                        customer service, fostering long-lasting partnerships,
                        and continuously improving our offerings to stay ahead
                        of the industry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="cardsWrapper">
                    <div className="consulting">
                      <h3>Core Values</h3>{" "}
                      <p>
                        {" "}
                        <ul>
                          <li>Client Success</li>
                          <li>Ethics and Integrity</li>
                          <li>Continuous Improvement</li>
                          <li>Teamwork</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="cardsWrapper">
                    <div className="placement">
                      <h3>Vision</h3>
                      <p>
                        {" "}
                        To shape the future of business through technology by
                        enabling our clients to reach their full potential.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
