import React, { useState, useEffect } from "react";
import "../Assets/Styles/Services.css";
import { IMAGES } from "../utils";
import { Link } from "react-router-dom";

const ServicesOfAceis = () => {
  return (
    <>
      <div className="setionCourse">
        <div className="course-image">
          <img src={IMAGES.ServicesSideImg} />
        </div>
        <div className="course-image1">
          <img src={IMAGES.staffingai} />
        </div>
        {/* <div className="course-image2"> 
          <img src={IMAGES.courseLeft1} />
        </div> */}
        {/*  <div className="course-image3">
          <img src={IMAGES.courseLeft} />
        </div> */}
        <div className="courseWrapper">
          <div className="container course-title-text">
            <h3>We Offer Best Services</h3>
            <p>
              At our company, we take pride in offering the best courses to help
              individuals enhance their skills and knowledge in various domains.
              Our courses are designed to provide comprehensive and practical
              learning experiences, equipping students with the expertise needed
              to succeed in today's competitive landscape.
            </p>
          </div>

          <div className="course-card-section">
            <div className="course-card-body">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Servicely - AI Workflows</h3>
                        <p>
                          Being the only partner company of Servicely in India,
                          we are dedicated to offering you the most innovative
                          and efficient services to help manage your business
                          operations..
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/AIWorkflow">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.stratehicstaffing}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Strategic Staffing</h3>
                        <p>
                          At Aceis, we have a workforce that is spread all over
                          India with just one mission to fulfil – finding the
                          right people to meet your specific requirements. You
                          may need people with a certain level of experience in
                          a particular domain ..
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/StaffingStrategy">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.staffing}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Cyber Security</h3>
                        <p>
                          Aceis as a Managed Security Systems Provider (MSSP)
                          understands the client’s crucial need for Managed
                          Security Systems and offers a wide range of security
                          services that use cutting-edge technologies ..
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/fullstackdev">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.cybersecurity}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>IT Consultancy Services</h3>
                        <p>
                          Graphic design is the practice of visual
                          communication, using imagery to convey information and
                          concepts to a viewer.
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/graphicsdesigning">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.ItConsultancy}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Offshore COE Execution</h3>
                        <p>
                          The biggest challenge for many organizations is access
                          to expertise. Every business wants to have efficient
                          processes that will lead to better services for
                          customers, and hence more revenue ..
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="">Read More..</Link>
                      </p>
                      <img src={IMAGES.ceo} className="course-inner-image" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="course-card-Wrapper">
                    <div className="card-content">
                      <div className="card-content-text">
                        <h3>Digital Marketing</h3>
                        <p>
                          Being the only partner company of Servicely in India,
                          we are dedicated to offering you the most innovative
                          and efficient services to help manage your business
                          operations..
                        </p>
                      </div>
                      <p className="course-info">
                        <Link to="/AIWorkflow">Read More..</Link>
                      </p>
                      <img
                        src={IMAGES.stratehicstaffing}
                        className="course-inner-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="adjust-space-bg">
            <div className="adjust-space-bg-inner"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesOfAceis;
