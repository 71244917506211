import React from "react";
import "../Assets/Styles/Companies.css";
import { IMAGES } from "../utils";
import Marquee from "react-fast-marquee";

const Companies = () => {
  const partner1 = [
    { img: IMAGES.Sutrahr },
    { img: IMAGES.Nextwave },
    { img: IMAGES.hopin },
    { img: IMAGES.umbrella },
    { img: IMAGES.staffcare },
    { img: IMAGES.timeandwatch },
  ];
  const partner2 = [
    { img: IMAGES.Sutrahr },
    { img: IMAGES.Nextwave },
    { img: IMAGES.hopin },
    { img: IMAGES.umbrella },
    { img: IMAGES.staffcare },
    { img: IMAGES.timeandwatch },
  ];

  return (
    <>
      <div className="companies">
        <div className="container">
          <div className="companies-heading">
            <h3>Companies</h3>
            <h5>Our Active Projects and Valuable Clients.</h5>
          </div>

          <div className="companies-section">
            
            <Marquee
              direction-scroll
              speed={80}
              delay={5}
              className="container-img"
            >
              <img src={IMAGES.Nextwave} alt="" />
              <img src={IMAGES.hopin} alt="" />
              <img src={IMAGES.umbrella} alt="" />
              <img src={IMAGES.staffcare} alt="" />
              <img src={IMAGES.timeandwatch} alt="" />
              <img src={IMAGES.Sutrahr} alt="" />
            </Marquee>
          </div>
        </div>
      </div>
    </>
  );
};

export default Companies;
