import React, { useState } from "react";
import "../Assets/Styles/ServicesOfAcies.css";
import { IMAGES } from "../utils";
import {
  RiNumber1,
  RiNumber2,
  RiNumber3,
  RiNumber4,
  RiNumber5,
} from "react-icons/ri";

const ServicesOfAcies = () => {
  const [s1state, setS1State] = useState(true);
  const [s2state, setS2State] = useState(false);
  const [s3state, setS3State] = useState(false);
  const [s4state, setS4State] = useState(false);
  const [s5state, setS5State] = useState(false);

  const onMouseHover_s1 = () => {
    setS1State(true);
    setS2State(false);
    setS3State(false);
    setS4State(false);
    setS5State(false);
  };

  const onMouseHover_s2 = () => {
    setS1State(false);
    setS2State(true);
    setS3State(false);
    setS4State(false);
    setS5State(false);
  };

  const onMouseHover_s3 = () => {
    setS1State(false);
    setS2State(false);
    setS3State(true);
    setS4State(false);
    setS5State(false);
  };

  const onMouseHover_s4 = () => {
    setS1State(false);
    setS2State(false);
    setS3State(false);
    setS4State(true);
    setS5State(false);
  };

  const onMouseHover_s5 = () => {
    setS1State(false);
    setS2State(false);
    setS3State(false);
    setS4State(false);
    setS5State(true);
  };

  return (
    <>
      <div className="sectionWhyAciesService">
        <div className="WhyAciesServiceWrapper">
          {s1state ? (
            <div className="WhyAciesServiceImgWrapper">
              <img
                src={IMAGES.VisualCommunication}
                className="WhyAciesServiceImg"
                alt=""
              />
            </div>
          ) : null}

          {s2state ? (
            <div className="WhyAciesServiceImgWrapper">
              <img
                src={IMAGES.BrandingAndMarketing}
                className="WhyAciesServiceImg"
                alt=""
              />
            </div>
          ) : null}

          {s3state ? (
            <div className="WhyAciesServiceImgWrapper">
              <img
                src={IMAGES.AestheticAppeal}
                className="WhyAciesServiceImg"
                alt=""
              />
            </div>
          ) : null}

          {s4state ? (
            <div className="WhyAciesServiceImgWrapper">
              <img
                src={IMAGES.Professionalism}
                className="WhyAciesServiceImg"
                alt=""
              />
            </div>
          ) : null}

          {s5state ? (
            <div className="WhyAciesServiceImgWrapper">
              <img
                src={IMAGES.Creativity}
                className="WhyAciesServiceImg"
                alt=""
              />
            </div>
          ) : null}

          <div className="WhyAciesServiceContent">
            <div className="WhyAciesServiceTitle">
              <h3>Our Services</h3>
              <p>
                We bring the breathe of our experience and industry knowledge to
                help you succeed. We work to understand your issues and are
                driven to ask better questions in the pursuit of making work.
              </p>
            </div>
            <div className="aciesservicecontent">
              <ul className="aciesservicecontent-list">
                <li onMouseOver={onMouseHover_s1}>
                  <div className="srNo">
                    <RiNumber1 size={35} className="srNoIcon" />
                    <h5>Strategic Staffing</h5>
                  </div>

                  <p>
                    Adjust global theme options and see design changes in
                    real-time. Adjust global theme.
                  </p>

                  <hr className="gdHr" />
                </li>

                <li onMouseOver={onMouseHover_s2}>
                  <div className="srNo">
                    <RiNumber2 size={35} className="srNoIcon" />
                    <h5>Cyber Security</h5>
                  </div>

                  <p>
                    Adjust global theme options and see design changes in
                    real-time. Adjust global theme.
                  </p>
                  <hr className="gdHr" />
                </li>

                <li onMouseOver={onMouseHover_s3}>
                  <div className="srNo">
                    <RiNumber3 size={35} className="srNoIcon" />
                    <h5>AI Workflow</h5>
                  </div>

                  <p>
                    Adjust global theme options and see design changes in
                    real-time. Adjust global theme.
                  </p>
                  <hr className="gdHr" />
                </li>

                <li onMouseOver={onMouseHover_s5}>
                  <div className="srNo">
                    <RiNumber5 size={35} className="srNoIcon" />
                    <h5>IT Consulting Services</h5>
                  </div>

                  <p>
                    Adjust global theme options and see design changes in
                    real-time. Adjust global theme.
                  </p>
                  <hr className="gdHr" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesOfAcies;
