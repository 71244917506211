import React from "react";
import "../Assets/Styles/Footer.css";
import { IMAGES } from "../utils";
import { CiFacebook, CiLinkedin, CiTwitter } from "react-icons/ci";
import { RxInstagramLogo } from "react-icons/rx";
import { RiFacebookCircleLine } from "react-icons/ri";
import {
  MdLocationOn,
  MdCall,
  MdEmail,
  MdKeyboardArrowRight,
} from "react-icons/md";

import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  const phoneNumber = "+91 7030226677";
  function handleDialClick() {
    window.open(`tel:${phoneNumber}`);
  }
  return (
    <>
      <div className="footer">
        <div className="footer-section">
          <div className="container ">
            <div className="row">
              <div className="col-lg-2 col-md-6 col-sm-12 itSolutions">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/Home">Home</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="#">About</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="#">Services </Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="#">News & Insight</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="#">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 itService">
                <h4>Our Service </h4>
                <ul>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">IT Service Automation</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Digital Marketing</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Salesforce</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Cybersecurity</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Splunk</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 itService">
                <h4>Industries </h4>

                <ul>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Automotive</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Telecom</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Medical</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">IT & ITES Industry</a>
                  </li>
                  {/* <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Splunk</a>
                  </li> */}
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 "></div>
              <div className="col-lg-4 col-md-6 col-sm-12 contact-through">
                <h4>Official Info</h4>
                <div className="d-flex align-items-start contactAddress">
                  <div className="locationIcon">
                    <MdLocationOn size={25} />
                  </div>
                  <div className="address">
                    <p>
                      Aceis Services Pvt. Ltd.
                      <br />
                      EFC Center, 4th Floor, Marisoft 1
                      <br /> Marigold, Kalyani Nagar,
                      <br />
                      Pune, India 411014
                    </p>
                    <a class="link--flip" aria-label="More Location">
                      <hr />
                    </a>
                  </div>
                </div>

                <div
                  className="d-flex align-items-start contactAddress footer-mb"
                  onClick={handleDialClick}
                >
                  <div className="phoneIcon">
                    <MdCall size={25} />
                  </div>
                  <div className="phone">
                    <p>+91 7030226677 </p>
                  </div>
                </div>

                <div className="d-flex align-items-start contactAddress">
                  <div className="EmailIcon">
                    <MdEmail size={25} />
                  </div>
                  <div className="email">
                    <p>info@aceiserv.com</p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="hrTag" />
            <div className="footer-middle">
              <div className="row">
                <div className="col-lg-9 col-md-12 col-sm-12 Information">
                  <ul className="info-ul">
                    <li>
                      <MdKeyboardArrowRight size={20} />
                      <a href="/">Client Support</a>
                    </li>
                    <li>
                      <MdKeyboardArrowRight size={20} />
                      <a href="/">Privacy Policy</a>
                    </li>
                    <li>
                      <MdKeyboardArrowRight size={20} />
                      <a href="/">Terms of Use</a>
                    </li>
                    <li>
                      <MdKeyboardArrowRight size={20} />
                      <a href="/">Sitemap</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 ourPartners">
                  <div>
                    <Carousel indicators={false} controls={false}>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.Sutrahr}
                          alt="Sutrahr"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.timeandwatch}
                          alt="timeandwatch"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.staffcare}
                          alt="staffcare"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.umbrella}
                          alt="umbrella"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.hopin}
                          alt="hopin"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.Nextwave}
                          alt="Nextwave"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.unilogo}
                          alt="unilogo"
                        />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-6 col-sm-12 footer-logo">
              <img src={IMAGES.Logo} alt="logo" className="logo" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 copyRight">
              ©Copyright {new Date().getFullYear()}{" "}
              <strong style={{ color: "#00B1C7" }}>ACEIS</strong> All Rights
              Reserved.
            </div>
            <div className="col-lg-4 col-md-12 text-md-end footer-socialIcons">
              <ul>
                <li>
                  <a href="#" target="_about">
                    <CiLinkedin size={25} className="social-icons linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_about">
                    <RxInstagramLogo size={25} className="social-icons insta" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_about">
                    <CiTwitter size={25} className="social-icons twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_about">
                    <RiFacebookCircleLine
                      size={25}
                      className="social-icons facebook"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <a href="#" target="_about">
        <BsWhatsapp className="wp-float-btn" size={30} />
      </a> */}
    </>
  );
};

export default FooterComponent;
