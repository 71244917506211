import React from "react";
import "../Assets/Styles/Banner.css";
import { IMAGES } from "../utils";
import { Carousel } from "react-bootstrap";

const Banner = () => {
  return (
    <>
      <div className="banner">
        <Carousel indicators={false} className="relative">
          <Carousel.Item interval={3000}>
            <div className="banner-section1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 banner-wrapper">
                    <div className="banner-width">
                      <h1 className="banner-h1">
                        {" "}
                        Enabling Technology Of The Future
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <div className="banner-section2">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-6 banner-wrapper">
                    <div className="banner-width">
                      <h1 className="banner-h1">Staffing</h1>
                      <h1 className="banner-h1">recruitment</h1>
                      <p className="banner-p1">
                        We assist you if you want to expand your business.
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-lg-7 col-md-6 col-sm-6">
                    <div className="banner-digital-img-wrapper">
                      <img src={IMAGES.AceisSlider} alt=" " />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <div className="banner-section3">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-6 banner-wrapper">
                    <div className="banner-width">
                      <h1 className="banner-h1">
                        Help Our Clients Reach Their Full Potential
                      </h1>
                      <p className="banner-p1"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default Banner;
