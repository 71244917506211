import React, { useState, useEffect } from "react";
import "../Assets/Styles/StaffingStrategy.css";
import { IMAGES } from "../utils";
import { GiCheckMark } from "react-icons/gi";
import { AnimationOnScroll } from "react-animation-on-scroll";

const StaffingStrategy = () => {
  return (
    <>
      <div className="web-course-section">
        <section className="web-course-wrapper">
          <div className="web-course-subwrapper">
            <div className="web-design-crs-banner">
              <div className=" web-design-banner-content">
                <div className="container">
                  <h3>Strategic Staffing</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="what-is-web">
                <div className="col-lg-6">
                  <div className="webcourse-right">
                    <div className="webcourse-right-content">
                      <div className="webCourse-text">
                        <p>
                          At Aceis, we have a workforce that is spread all over
                          India with just one mission to fulfil – finding the
                          right people to meet your specific requirements. You
                          may need people with a certain level of experience in
                          a particular domain, or in a specific location. No
                          matter what you require, our competent staffing
                          services division will find people who will meet the
                          unique needs of your company.
                        </p>
                        <p>
                          {" "}
                          We understand that every company has its own culture,
                          values, and expectations from its employees. The
                          better we can understand your unique way of
                          functioning, the easier it will be for us to tailor
                          our search and narrow down the field of potential
                          candidates for employment at your organization.
                        </p>
                        <p>
                          Let us help you procure a winning team that will
                          improve productivity and increase revenues. Our strong
                          presence, nationally and internationally, helps ensure
                          that we can find the perfect resources you need in any
                          market where you do business. Let us be your partner
                          in success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
              </div>
            </div>
          </div>
        </section>

        <section className="what-is-ui-section">
          <div className="container what-is-ui-wrapper">
            <div className="row">
              <div className="col-lg-5">
                <div className="ui-image-wrapper">
                  <img
                    src={IMAGES.WhatIsUserInterfaceUI}
                    className="crs-ui-img"
                    alt="what is ui img"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                {/* <div className="curve"></div> */}
                <div className="ui-content-wrapper">
                  <div>
                    <h3>What is Lorem Ipsum?</h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                   
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="what-is-ux-section">
          <div className="container what-is-ux-wrapper">
            <div className="row">
              <div className="col-lg-7">
                <div className="ux-content-wrapper">
                  <div>
                    <h3>What is Lorem Ipsum?</h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="ux-image-wrapper">
                  <img
                    src={IMAGES.WhatIsUserExperience}
                    className="crs-ux-img"
                    alt="what is ux img"
                  />
                </div>
              </div>
            </div>
            <div className="what-is-ux-bottom">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default StaffingStrategy;
